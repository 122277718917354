import {
  Component,
  Input,
  ViewEncapsulation,
  ElementRef,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject
} from '@angular/core';
import {DomSanitizer, SafeStyle} from '@angular/platform-browser';

import {Entity} from 'apps/_models/entities';
import {NgxPermissionsObject, NgxPermissionsService} from "ngx-permissions";
import {APPS_CONFIG} from "../../../apps-config.constants";
import {IAppsConfig} from "../../../apps-config.interface";
import { PCMS_PLAYER_BASE_URL, PCMS_TEAM_BASE_URL } from 'apps/contract/_constants/constants';

@Component({
  selector: 'entity-context',
  templateUrl: './entity-context.component.html',
  styleUrls: ['./entity-context.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EntityContextComponent {

  @Input('avatar-size') avatarSize: number = 36;
  @Input('entity') entity: Entity;
  @Input('isGraphTooltip') isGraphTooltip: boolean;
  @Input('tag') tag: any;
  @Input('showAge') showAge: boolean = true;
  @Input('showShortcuts') showShortcuts: boolean = false;
  @Input('isMobile') isMobile: boolean = false;
  @Input('isSearch') isSearch: boolean = false;
  @Input('affiliationOverride') affiliationOverride: Entity;
  @Input('app') app: string = 'bild';

  readonly THUNDER_ID = 2;
  readonly BLUE_ID = 11569;

  avatarSizePx: SafeStyle;
  matIconStyle: SafeStyle;
  perms: NgxPermissionsObject;

  get affiliation(): Entity {
    return this.affiliationOverride ? this.affiliationOverride : this.entity.affiliation;
  }

  get isPlayer(): boolean {
    return this.entity?.type == 'Person' && this.entity.subtype?.id === 1;
  }

  get isThunderAffiliated(): boolean {
    return (this.entity?.type == 'Person' && [this.THUNDER_ID, this.BLUE_ID].includes(this.affiliation?.id)) ||
      ([this.THUNDER_ID, this.BLUE_ID].includes(this.entity?.id));
  }

  @Output('subrouteSelected') subrouteSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(APPS_CONFIG) public config: IAppsConfig,
    protected domSanitizer: DomSanitizer,
    protected permissionsService: NgxPermissionsService,
    public elementRef: ElementRef,
    ) {}

  ngOnInit() {
    this.perms = this.permissionsService.getPermissions();
    let className;
    if (this.entity) {
      className = this.entity.type.toLowerCase();
    } else if (this.tag) {
      className = this.tag.name.replace(' ', '_').toLowerCase();
    }
    this.avatarSizePx = this.domSanitizer.bypassSecurityTrustStyle(`${this.avatarSize}px`);
    const avatarColumnSize = this.entity?.type === 'Playcall' ? 8 : this.avatarSize + 8;
    this.elementRef.nativeElement.style = `grid-template-columns: ${avatarColumnSize}px 8fr 2fr`;
    this.elementRef.nativeElement.classList.add(className);
    if (this.isSearch) {
      const avatarColumnSize = this.entity?.type === 'Playcall' ? 8 : this.avatarSize + 20;
      this.elementRef.nativeElement.style = `grid-template-columns: ${avatarColumnSize}px 280px 1fr`;
    } else if (this.showShortcuts) {
      this.elementRef.nativeElement.style = `grid-template-columns: ${avatarColumnSize}px 160px 1fr`;
    }

    if (this.entity?.type === 'FocusGroup') {
      this.matIconStyle = this.domSanitizer.bypassSecurityTrustStyle(`font-size: ${this.avatarSize}px; width: ${this.avatarSize}px; height: ${this.avatarSize}px`);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entity) {
      if (changes.entity.previousValue !== undefined) {
        this.elementRef.nativeElement.classList.remove(changes.entity.previousValue?.type.toLowerCase());
      }
      if (changes.entity.currentValue !== undefined) {
        this.elementRef.nativeElement.classList.add(changes.entity.currentValue?.type.toLowerCase());
      }
    }
    if (changes.tag) {
      if (changes.tag.previousValue !== undefined) {
        this.elementRef.nativeElement.classList.remove(changes.tag.previousValue?.name.replace(' ', '_').toLowerCase());
      }
      if (changes.tag.currentValue !== undefined) {
        this.elementRef.nativeElement.classList.add(changes.tag.currentValue?.name.replace(' ', '_').toLowerCase());
      }
    }
  }

  navigateToRoute(clickEvent, subroute: string) {
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
    this.subrouteSelected.emit({entity: this.entity, subroute: subroute});
  }

  affiliationClicked(clickEvent) {
    clickEvent.preventDefault();
    clickEvent.stopPropagation();
    if (this.affiliation.id === this.config.NBA_ENTITY_ID) {
      this.affiliation.type = 'League';
    } else {
      this.affiliation.type = 'Team';
    }
    this.subrouteSelected.emit({entity: this.affiliation});
  }

  getEntityRoute(entity: Entity, subroute?: string, isAffiliation?: boolean): string {
    let route: string;
    switch (true) {
      case entity.isLeague || entity.id == this.config.NBA_ENTITY_ID:
        route = `bild/entities/leagues/${entity.id}`;
        break;
      case entity.isPerson:
        route = `bild/entities/people/${entity.id}`;
        break;
      case entity.isTeam || isAffiliation:
        route = `bild/entities/teams/${entity.id}`;
        break;
      case entity.isSubscriptionOrganization:
        route = `bild/entities/subscription-organizations/${entity.id}`;
        break;
      case entity.isTag:
        route = `bild/entities/tags/${entity.id}`;
        break;
      default:
        route = `bild/entities/focus-groups/${entity.id}`;
    }

    if (subroute) {
      route += `/${subroute}`;
    }
    return route;
  }

  getPCMSURL(entity: Entity) {
    if (entity.isPerson) {
      return PCMS_PLAYER_BASE_URL + entity.nbaID + '/transactions/list';
    }
    else if (entity.isTeam) {
      return PCMS_TEAM_BASE_URL + entity.nbaID;
    }
  }
}
