<entity-avatar *ngIf="entity?.optimizedImageUrl" [size]="avatarSize" [entity]="entity"></entity-avatar>
<div *ngIf="entity" class="name" [style.height]="avatarSizePx" [ngClass]="{'show-age': showAge, 'is-link': showShortcuts, 'graph-tooltip': isGraphTooltip}">
  <a [href]="getEntityRoute(entity)" (click)="$event.preventDefault()">
    <span>{{ isMobile ? entity.firstInitialLastName : entity.name }}</span>
  </a>
  <small>{{ affiliation?.name }}</small>
  <small *ngIf="entity.type == 'Team' && entity.location !== null">{{ entity.location }}</small>
  <small *ngIf="entity.type == 'FocusGroup'">Focus Group</small>
</div>
<div *ngIf="entity && !showShortcuts" class="extra" [ngClass]="{'show-age': showAge}" [style.height]="avatarSizePx">
  <ng-container *ngIf="showAge">
    <span *ngIf="entity.type == 'Person' && entity.subtype?.name !== 'Staff'" >{{ entity.age }}</span>
  </ng-container>
  <span *ngIf="entity.type == 'Team' && entity.league?.abbrev !== null">{{ entity.league.abbrev }}</span>
  <span *ngIf="entity.type == 'Team' && entity.league?.abbrev == null && entity.league?.country !== null">{{ entity.league.country }}</span>
  <span *ngIf="entity.type == 'League' && entity.country !== null">{{ entity.country }}</span>
</div>
<ng-container *ngIf="tag && !isSearch">
  <mat-icon class="tag-icon">local_offer</mat-icon>
  <div class="tag-info">
    <span>{{ tag.name }}</span>
    <small>{{ tag.isMemo ? 'Memo Tag' : 'Post Tag' }}</small>
  </div>
</ng-container>
<div class="tag-container" *ngIf="tag && isSearch">
  <mat-icon class="tag-icon">local_offer</mat-icon>
  <div class="tag-info">
    <span>{{ tag.name }}</span>
    <small>{{ tag.isMemo ? 'Memo Tag' : 'Post Tag' }}</small>
  </div>
</div>
<div *ngIf="showShortcuts && entity" class="search-shortcuts">
  <ng-template [ngxPermissionsOnly]="['viewAllMetrics']">
    <a [href]="getEntityRoute(entity, 'metrics')">
      <mat-card class="subnav-card" (click)="navigateToRoute($event, 'metrics')" matTooltip="Metrics" *ngIf="(entity.type == 'Person' && entity.subtype?.id === 1) || entity.type == 'Team'">
        <mat-icon class="metrics-icon" color="primary">timeline</mat-icon>
      </mat-card>
    </a>
  </ng-template>
  <ng-template [ngxPermissionsOnly]="['viewAllDevelopment']">
    <a [href]="getEntityRoute(entity, entity.type === 'Team' ? 'player-development' : 'development')" *ngIf="isThunderAffiliated && (isPlayer || entity.type == 'Team')">
      <mat-card class="subnav-card" (click)="navigateToRoute($event, entity.type === 'Team' ? 'player-development' : 'development')" matTooltip="Player Development">
        <mat-icon class="eval-icon" svgIcon="bild-development"></mat-icon>
      </mat-card>
    </a>
  </ng-template>
  <a [href]="getEntityRoute(entity, 'evaluation')" *ngIf="entity.type == 'League' || (entity.type == 'Person' && (isThunderAffiliated || entity.subtype?.id === 1))">
    <mat-card class="subnav-card" (click)="navigateToRoute($event, 'evaluation')" matTooltip="Evaluation">
      <mat-icon class="eval-icon" svgIcon="bild-evaluation"></mat-icon>
    </mat-card>
  </a>
  <ng-template [ngxPermissionsOnly]="['viewAllPhysicalMeasurements']">
    <a [href]="getEntityRoute(entity, 'physical')" *ngIf="isThunderAffiliated && (isPlayer || entity.type == 'Team')">
      <mat-card class="subnav-card" (click)="navigateToRoute($event, 'physical')" matTooltip="Physical">
        <mat-icon class="eval-icon" svgIcon="bild-medical"></mat-icon>
      </mat-card>
    </a>
  </ng-template>
  <a [href]="getEntityRoute(entity, 'personal')" *ngIf="entity.type == 'Person'">
    <mat-card class="subnav-card" (click)="navigateToRoute($event, 'personal')" matTooltip="Personal">
      <mat-icon class="person-icon">person</mat-icon>
    </mat-card>
  </a>
  <a [href]="getEntityRoute(entity, 'roster')" *ngIf="entity.type == 'Team'">
    <mat-card class="subnav-card" (click)="navigateToRoute($event, 'roster')" matTooltip="Roster">
      <mat-icon class="person-icon">people</mat-icon>
    </mat-card>
  </a>
  <a [href]="getPCMSURL(entity)" *ngIf="app == 'contract' && (entity.isPerson || entity.isTeam)">
    <mat-card class="subnav-card" (click)="navigateToRoute($event, getPCMSURL(entity))" matTooltip="PCMS">
      <img src="assets/bild-icons/bild-pcms.png" class="pcms-icon">
    </mat-card>
  </a>
</div>
